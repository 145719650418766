const RotatingText = [
  "React Developer",
  "NextJS Developer",
  "React Native",
  "Web Developer",
  "Ui Developer",
  "Front-End Developer",
];

const skillProgress = [
  {
    title: "Responsive Design",
    progress: 100,
  },
  {
    title: "UI Design",
    progress: 100,
  },
  {
    title: "ReactJS",
    progress: 100,
  },
  {
    title: "NextJS",
    progress: 80,
  },
  {
    title: "HTML5 % CSS3",
    progress: 100,
  },
  {
    title: "Firebase",
    progress: 95,
  },
];
const aboutContent =
  "I started my journey in the world of computers from an young age, now I’m 24 years old, I Completed my Bachlor Computer of Applications Degree from IGNOU University. Web development is my center of interest, I always love the idea of cross-platform development, 1-n one code base deploy into almost any platform, which web technology like Javascript enables me to do. I also like creating Interactive UI components for better UX and share those design and codes to the world through Github, Codepen and Linkedin.";
export { RotatingText, skillProgress, aboutContent };
