import React from "react";
import Navbar from "./Navbar";
import Form from "./Form";
import Breadcrumbs from "../components/Pages/Breadcrumbs";

const Admin = ({ setAdmin }) => {
  return (
    <div className="text-white vh-100">
      <Navbar setAdmin={setAdmin} />
      <div className="container py-3">
        <Breadcrumbs path="Add Posts" />
      </div>
      <div className="container border mt-3">
        <div className=" p-4">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Admin;
