import React from "react";
import { FaSearch } from "react-icons/fa";
import { MdTune } from "react-icons/md";
import AllProjectContent from "./allProjectContent";
import { useState } from "react";
import { getDocs } from "firebase/firestore";
import dbref from "../../firebase/CollectionRef";
import { useEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import Navbar from "../../admin/Navbar";
import { Link } from "react-router-dom";

const AllProjects = ({ isAdmin, setAdmin }) => {
  const [getPost, setGetPost] = useState([]);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const getPosts = async () => {
    const data = await getDocs(dbref);
    const newData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const sortdata = newData.sort((a, b) => b.time - a.time);
    setGetPost(sortdata);
  };
  useEffect(() => {
    getPosts();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mb-3">
      {isAdmin ? <Navbar setAdmin={setAdmin} /> : ""}

      <div className="container py-4" data-aos="fade-up">
        <Breadcrumbs path={"All Projects"} />
      </div>
      <div className="bg-white " data-aos="zoom-out">
        <div className="container pt-3 pb-5">
          <div className="d-flex justify-content-center pt-3">
            <input
              placeholder="Search Project Here ..."
              type="search"
              value={search}
              id="HomeSearch"
              onChange={(e) => setSearch(e.target.value)}
              className="text- bg-white w-75 border border-end-0 border-dark ps-3 pe-3 py-3"
            />
            <span className="material-icons py-3 px-3 bg-black text-white border-dark border border-start-0">
              <FaSearch />
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5" data-aos="zoom-in-up">
        <div className="container">
          <div className="d-flex justify-content-between text-white mb-3 align-items-center">
            {sort === "" ? (
              <span> All Projects ...</span>
            ) : (
              <span> {sort} Projects ...</span>
            )}

            <div
              className="d-flex align-items-center dropstart"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              <MdTune className="fs-2" />
              {/* <span className="ms-2">Filter</span> */}
            </div>
            <ul class="dropdown-menu">
              <li>
                <span
                  class="dropdown-item pointer"
                  onClick={() => setSort("Personal")}
                >
                  Personal Projects
                </span>
              </li>
              <li>
                <span
                  class="dropdown-item pointer"
                  onClick={() => setSort("Work")}
                >
                  Work Projects
                </span>
              </li>
              <li>
                <span
                  class="dropdown-item pointer"
                  onClick={() => setSort("Recent")}
                >
                  Recent Projects
                </span>
              </li>
              <li>
                <span class="dropdown-item pointer" onClick={() => setSort("")}>
                  All Projects
                </span>
              </li>
            </ul>
          </div>

          <div className="border p-3">
            <div className="overflow-y-scroll" style={{ height: "500px" }}>
              {sort === ""
                ? getPost
                    .filter((item) => {
                      return search.toLowerCase() === ""
                        ? item
                        : item.title.toLowerCase().includes(search);
                    })
                    .map((data) => (
                      <Link to={`/project-details/${data.id}`} key={data.id}>
                        <AllProjectContent data={data} />
                      </Link>
                    ))
                : getPost
                    .filter((data) => data.category === sort)
                    .filter((item) => {
                      return search.toLowerCase() === ""
                        ? item
                        : item.title.toLowerCase().includes(search);
                    })
                    .map((data) => (
                      <Link to={`/project-details/${data.id}`} key={data.id}>
                        <AllProjectContent data={data} />
                      </Link>
                    ))}

              {/* {getPost
                .filter((data) => data.category === sort)
                .map((data) => (
                  <Link to={`/project-details/${data.id}`} key={data.id}>
                    <AllProjectContent data={data} />
                  </Link>
                ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProjects;
