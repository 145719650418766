import React, { useEffect, useState } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const ProjectView = () => {
  const [req, setReq] = useState([]);
  const params = useParams();
  const { id } = params;
  const docRef = doc(db, "Posts", `${id}`);
  useEffect(() => {
    getDoc(docRef)
      .then((res) => setReq(res.data()))
      .catch((err) => console.log(err));

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [id]);
  return (
    <div className="container mt-3 vh-100">
      <Breadcrumbs path={req.title} />
      <h3 className="text-center mt-3" data-aos="fade-up">
        {req.title}
      </h3>
      <div className="mt-4 row flex-column-reverse flex-sm-row flex-md-row res-gap">
        <div className="col-12 col-md-8" data-aos="zoom-in-up">
          <div className="overflow-auto vh-100">
            <img src={req.poster} alt="" className="w-100 " />
          </div>
        </div>
        <div id="" className="col-12 col-md-4">
          <div className="border px-4 py-4 text-white" data-aos="zoom-out">
            <h3 className="" data-aos="fade-up">
              Project information
            </h3>
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-danger">
                <li>Category :</li>
                <li>Client :</li>
                <li>Project Date :</li>
                <li>Project URL :</li>
              </div>
              <div className="d-flex flex-column">
                <span>{req.category}</span>
                <span>{req.client}</span>
                <span>{req.date}</span>
                <a
                  href={req.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-warning"
                >
                  Click to Visit{" "}
                  <span className="text-decoration-underline hover-blue">
                    Web Page
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-2 px-1" data-aos="zoom-in-up">
            <h5>Details of Project</h5>
            <p>{req.desciption}</p>
          </div>
          {req?.stack?.length === 0 ? (
            ""
          ) : (
            <div className="mt-2 px-1" data-aos="zoom-in-up">
              <h5>Worked On</h5>
              <p>
                {req?.stack?.map((stack, index) => (
                  <button
                    key={index}
                    type="button"
                    class="btn btn-danger"
                    style={{
                      padding: "4px",
                      marginRight: "5px",
                      marginBottom: "3px",
                    }}
                    data-mdb-ripple-init
                    //onClick={() => stackRemove(stack)}
                  >
                    {stack}
                  </button>
                ))}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectView;
