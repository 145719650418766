import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ path }) => {
  return (
    <div id="breadcrumb">
      <div className="text-white d-flex lidecoration ">
        <li>
          <Link to={"/"} className="me-2 text-white">
            Home
          </Link>
        </li>
        <li className="text-secondary me-2">
          <IoIosArrowForward />
        </li>
        {path ? (
          <li className="text-danger">{path}</li>
        ) : (
          <li className="text-danger">Project Details</li>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
