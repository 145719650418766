import React from "react";
import ReactRotatingText from "react-rotating-text";
import { RotatingText } from "../Home/sudoarun";
import { Link } from "react-router-dom";
import {} from "firebase/database";
// import WOW from "wowjs";

const HeroComp = ({ bgImg }) => {
  const myStyle = {
    backgroundImage: `url('${bgImg}')`,
  };

  return (
    <div id="home" className="section" style={myStyle}>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8  text-white">
            <h3>
              Simple and{" "}
              <Link to={"/login"} className="text-white">
                Elegant
              </Link>
            </h3>
            <h1 data-aos="fade-up">Arun Thakur</h1>
            <div id="rotatingText">
              <ReactRotatingText
                items={RotatingText}
                emptyPause={250}
                pause={2000}
                typingInterval={70}
              />
            </div>
            <p className="text-secondary">
            Hi There. I'm Arun Thakur, A front-end developer experienced in latest technology like React, Nextjs, skilled in creating dynamic and user-friendly web applications.</p>
            <div data-aos="zoom-in-up">
              <span className="text-secondary">
                To Get My CV Click on
                <span className="cvtext ms-2">Lower Button</span>
              </span>
            </div>
            <span
              // href="https://sudoarun.droped.workers.dev/0:/CV/resume.pdf"
           
              onClick={()=>alert("Link not available")}
              className="btn btn-default smoothScroll "
              data-aos="fade-up"
            >
              Download CV
            </span>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default HeroComp;
