import React from "react";
import {
  FaCodepen,
  FaGithub,
  FaLinkedinIn,
  FaTelegramPlane,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 " data-aos="zoom-in-down">
            <p>Copyright &copy; 2022 Arun Personal Profile</p>
            <hr className="w-100" />
            <ul className="social-icon">
              <li>
                <a
                  href="https://www.linkedin.com/in/sudoarun/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/sudoarun"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://telegram.me/sudoarun"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTelegramPlane />
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/sudoarun"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub />
                </a>
              </li>
              <li>
                <a
                  href="https://codepen.io/sudoarun"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaCodepen />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
