import React from "react";
import { aboutContent, skillProgress } from "../Home/sudoarun";

const About = () => {
  return (
    <div id="about" className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xs-11 " data-aos="fade-up">
            <h2>ABOUT Arun</h2>
            <h4>UI DESIGNER & DEVELOPER</h4>
            <p className="fs-7">{aboutContent}</p>
          </div>
          <div className="col-md-6 col-xs-11" data-aos="fade-down">
            {skillProgress.map((el, index) => (
              <span key={index}>
                <span className={index === 0 ? "text-top" : ""}>
                  {el.title} <small>{el.progress}%</small>
                </span>
                <div className="progress mb-3">
                  <div
                    className="progress-bar progress-bar-danger"
                    role="progressbar"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${el.progress}%` }}
                  ></div>
                </div>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
