import React from "react";

const ProjectPost = ({ title, author, posterImg }) => {
  return (
    <div className="postContainer mt-3">
      <img src={posterImg} alt="Avatar" className="PostImage" />
      <div className="Imageoverlay">
        <div className="overlayText">
          <span className="text-decoration-underline fw-bold">{title}</span>
          <br />
          <span className="fs-6">Author : {author}</span>
          <br />
          <span className="fs-6">
            Click Here to{" "}
            <span className="text-decoration-underline fw-bold hover-blue">
              View Project
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectPost;
