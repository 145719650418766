import React, { useEffect, useState } from "react";
import Work from "../Pages/Work";
import HeroComp from "../Pages/Hero";
import About from "../Pages/About";
import Projects from "../Pages/Projects";
import Contact from "../Pages/Contact";
import Footer from "../Footer/Footer";
import Loader from "../loader/loader";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
// import Slider from "../SideSlide/Slider";

const Home = ({ loader, setLoader }) => {
  const [bgImg, setBgImg] = useState("");
  const imgref = collection(db, "sudoarun");
  const getImg = async () => {
    const reImg = await getDocs(imgref);
    const imgDb = reImg.docs.map((data) => ({
      imgDb: data.data(),
    }));
    setBgImg(imgDb[0].imgDb.ImageUrl);
    setLoader(false);
  };
  useEffect(() => {
    getImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {bgImg === "" ? (
        <div className="vh-100 d-flex justify-content-center align-items-center position-relative">
          <Loader />
        </div>
      ) : (
        <div>
          <HeroComp bgImg={bgImg} />
          <Work />
          <About />
          <Projects />
          <Contact />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
