import { message } from "antd";
import React from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import icon from "../assets/images/portfolio.png";

const Navbar = ({ setAdmin }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const Logout = () => {
    messageApi.open({
      type: "success",
      content: "Log Out Success !!",
    });
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      {contextHolder}
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FaBars />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Link to={"/admin"} className="navbar-brand mt-lg-0">
            <img src={icon} height="50" alt="sudoarun" loading="lazy" />
          </Link>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link" href="#!">
                Add Post
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/all-projects"} className="nav-link" href="#!">
                Get Posts
              </Link>
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-center">
          <button
            type="btn"
            className="btn btn-danger"
            onClick={() =>
              Logout() ||
              setTimeout(() => setAdmin(false), 1000) ||
              clearTimeout(setTimeout)
            }
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
