import { message } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SlReload } from "react-icons/sl";

const Auth = ({ setAdmin }) => {
  const [Password, setPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Login Success",
    });
  };
  const Fail = () => {
    messageApi.open({
      type: "warning",
      content: "Login Failed !!",
    });
  };
  const formHandler = (e) => {
    e.preventDefault();
    if (Password === "hr30@arun") {
      success();
      setAdmin(true);

      setPassword("");
      setTimeout(() => {
        navigate("/admin");
      }, 1200);
    } else {
      Fail();
      setPassword("");
    }
  };
  return (
    <div className="">
      <div className="d-flex justify-content-center align-items-center vh-100 ">
        <form
          onSubmit={formHandler}
          className="border p-5 rounded position-relative"
        >
          <Link
            to={"/"}
            className="position-absolute text-white top-0 end-0 me-2"
            data-aos="flip-right"
          >
            <SlReload />
            <span className="ms-1 text-decoration-underline text-warning">
              Home
            </span>
          </Link>

          {contextHolder}
          <h4 className="mb-3" data-aos="fade-up">
            Login
          </h4>
          <input
            placeholder="Enter Password"
            className="form-control bg-black rounded-0 text-white"
            value={Password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            data-aos="zoom-out"
          />
          <button
            className="w-100 btn btn-danger mt-3"
            data-aos="fade-down"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Auth;
