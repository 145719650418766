import React, { useEffect, useState } from "react";
import ProjectPost from "./ProjectPost";
import { FaArrowRight, FaGithub } from "react-icons/fa";
import { getDocs, orderBy } from "firebase/firestore";
import dbref from "../../firebase/CollectionRef";
import { Space, Spin } from "antd";
import { Link } from "react-router-dom";

const Projects = () => {
  const [posts, setPost] = useState([]);

  const getPosts = async () => {
    const data = await getDocs(dbref, orderBy("date"));
    const newData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const sortdata = newData.sort((a, b) => b.time - a.time);
    setPost(sortdata);
  };
  useEffect(() => {
    getPosts();
  }, []);
  return (
    <div id="portfolio" className="section">
      <div className="container">
        <div>
          <h2 className="" data-aos="fade-up">
            My Projects
          </h2>
          <div className="row overflow-hidden" style={{ height: "600px" }}>
            {posts.length ? (
              posts.map((post) => (
                <div
                  className="col-12 col-sm-4 "
                  data-aos="fade-up"
                  key={post.id}
                >
                  <Link to={`/project-details/${post.id}`}>
                    <ProjectPost
                      title={post.title}
                      author={post.client}
                      posterImg={post.poster}
                    />
                  </Link>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <Space size="middle">
                  <Spin size="large" />
                  <span className="text-white">loading ...</span>
                </Space>
              </div>
            )}
          </div>
          <div
            className="showMoreBG col-12 mt-n5 py-n5 shadow-4"
            data-aos="fade-down"
          >
            <Link
              to={"/all-projects"}
              className="mt-3 bg-black text-white border rounded border-white  fw-bold px-4 py-3"
            >
              Check all Posts Here
            </Link>
          </div>
        </div>

        <div className="githubpro">
          <div>
            <h3>Want to See More Projects?</h3>
          </div>
          <div className="gitpro">
            <div>
              <p>
                Checkout Here
                <span className="ms-2">
                  <FaArrowRight />
                </span>
              </p>
            </div>
            <div className="d-flex align-items-center">
              <a
                href="https://github.com/sudoarun"
                className="btn btn-default smoothScroll "
                data-aos="zoom-in-up"
                target="_blank"
                rel="noreferrer"
              >
                <i className="me-2">
                  <FaGithub />
                </i>
                Github
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
