import React from "react";

const AllProjectContent = ({ data }) => {
  //   console.log(title);
  return (
    <div className="row pb-3 mt-3 border-bottom border-secondary">
      <div className="col-5 col-sm-3">
        <img
          alt=""
          src={data.poster}
          className="w-100 rounded ProjectContentIMG"
        />
      </div>
      <div className="col-7 col-sm-9 text-white text-left">
        <div className="d-flex justify-content-between">
          <div className="">
            <div>
              <h5>{data.title}</h5>
            </div>
            <div>
              <li>Category : {data.category}</li>
              <li>Client : {data.client}</li>
              <li>Data : {data.date}</li>
              {/* <li>Description : {data.desciption}</li> */}
              <li>Rating</li>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex col-12 col-sm-3 align-items-end">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-between text-white">
            <span>Price :-</span>
            <span>399rs</span>
          </div>
          <div>
            {btn ? (
              <button
                className="py-2 px-4 border-0 rounded bg-danger text-white"
                id="cancelbtn"
              >
                Cancel
              </button>
            ) : (
              <Link to={shop} className="py-2 px-4 border bg-black text-white">
                Check Shop
              </Link>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AllProjectContent;
