import React from "react";
import { FaMobileAlt } from "react-icons/fa";
import { IoBrowsersOutline, IoLogoReact } from "react-icons/io5";

const Work = () => {
  return (
    <div id="work" className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              WHAT I DO
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-xs-11 " data-aos="fade-down">
            <div className="media d-flex justify-content-between">
              <div className="media-object media-left">
                <i className="">
                  <FaMobileAlt />
                </i>
              </div>
              <div className="media-body">
                <h3 className="media-heading">Responsive Mobile</h3>
                <p>I make Unhandled unresponsive Site to Responsive easily.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-11 " data-aos="fade-up">
            <div className="media d-flex justify-content-between">
              <div className="media-object media-left">
                <i className="">
                  <IoLogoReact />
                </i>
              </div>
              <div className="media-body">
                <h3 className="media-heading">React</h3>
                <p>I make React, React Native and Electron App .</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-11 " data-aos="fade-down">
            <div className="media d-flex justify-content-between">
              <div className="media-object media-left">
                <i className="">
                  <IoBrowsersOutline />
                </i>
              </div>
              <div className="media-body">
                <h3 className="media-heading">UI Design</h3>
                <p>
                  I Make great UI Designs With The Help of Bootstrap,
                  TailWindCSS, and Custom CSS
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
