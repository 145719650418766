import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/home";
import ProjectView from "./components/Pages/ProjectView";
import Admin from "./admin/admin";
import { useEffect, useState } from "react";
import Auth from "./Auth/auth";
import ErrorPage from "./components/Pages/errorPage";
import AllProjects from "./components/Pages/All Projects";
import "aos/dist/aos.css";
import AOS from "aos";

function PrivateRoute({ isAdmin, children }) {
  if (isAdmin) {
    return children;
  }
  return <Navigate to={"/login"} />;
}

function App() {
  const [isAdmin, setAdmin] = useState(false);
  const [loader, setLoader] = useState(true);
  setTimeout(() => {
    setLoader(false);
  }, 2000);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<Home loader={loader} setLoader={setLoader} />}
        />
        <Route path="/project-details/:id" element={<ProjectView />} />
        <Route
          path="/all-projects"
          element={<AllProjects isAdmin={isAdmin} setAdmin={setAdmin} />}
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute isAdmin={isAdmin}>
              <Admin setAdmin={setAdmin} />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Auth setAdmin={setAdmin} />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
