import React from "react";

const ErrorPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <h4 className="text-white">404 Page Not Found</h4>
    </div>
  );
};

export default ErrorPage;
