import React, { useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase/firebase";
import { addDoc, serverTimestamp } from "firebase/firestore";
import dbref from "../firebase/CollectionRef";
import { message } from "antd";

const Form = () => {
  const [poster, setposter] = useState("");
  const [postername, setPostername] = useState("");
  const [stackInput, setStackInput] = useState([]);
  const [stackInputValue, setStackInputValue] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const [state, setState] = useState({
    Projectname: "",
    client: "",
    category: "",
    date: "",
    url: "",
    description: "",
  });

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setState((data) => {
      return { ...data, [name]: value };
    });
  };

  const posterEmpty = () => {
    setposter("");
    setPostername("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const metadata = {
      contentType: "image",
    };
    const success = () => {
      messageApi.open({
        type: "success",
        content: "Data Uploaded Successfully",
      });
    };
    const Fail = () => {
      messageApi.open({
        type: "warning",
        content: "Failed To Upload Data !!",
      });
    };

    const imgRef = ref(storage, `Posters/${state.Projectname}/` + poster.name);
    uploadBytes(imgRef, poster, metadata)
      .then(() =>
        getDownloadURL(imgRef)
          .then((posterUrl) => {
            addDoc(dbref, {
              title: state.Projectname,
              client: state.client,
              category: state.category,
              date: state.date,
              url: state.url,
              poster: posterUrl,
              desciption: state.description,
              stack: stackInput,
              time: serverTimestamp(),
            })
              .then(() => success())
              .catch(() => Fail());
          })
          .catch((err) => console.log("url error :" + err))
      )
      .catch((err) => console.log("image error: " + err));

    setState({
      category: "",
      client: "",
      date: "",
      description: "",
      Projectname: "",
      url: "",
    });
    setStackInputValue("");
    posterEmpty();
  };
  const onHandleStackInput = (e) => {
    setStackInputValue(e.target.value);
  };
  const onAdd = () => {
    if (stackInputValue === "" || stackInput.includes(stackInputValue)) {
      setStackInputValue("");
      return;
    }
    setStackInput([...stackInput, stackInputValue]);
    setStackInputValue("");
  };
  const stackRemove = (value) => {
    if (stackInput.includes(value)) {
      const filteredList = stackInput.filter((item) => item !== value);
      setStackInput(filteredList);
    }
  };
  return (
    <form onSubmit={onSubmit}>
      {contextHolder}
      <div className="row">
        <div className="col-12 col-sm-6">
          <input
            className="form-control bg-black text-white"
            placeholder="Enter Project Name"
            value={state.Projectname}
            name="Projectname"
            onChange={onChangeHandle}
            required
          />
        </div>
        <div className="col-12 col-sm-6">
          <input
            className="form-control bg-black text-white"
            placeholder="Project Client"
            value={state.client}
            name="client"
            onChange={onChangeHandle}
            required
          />
        </div>
        <div className="col-12 col-sm-6">
          <input
            className="form-control bg-black text-white"
            placeholder="Enter Project Category"
            value={state.category}
            name="category"
            onChange={onChangeHandle}
            required
          />
        </div>
        <div className="col-12 col-sm-6">
          <input
            className="form-control bg-black text-white"
            placeholder="Enter Project Date"
            value={state.date}
            name="date"
            onChange={onChangeHandle}
            required
          />
        </div>

        <div className="col-12 col-sm-6">
          <input
            className="form-control  bg-black text-white"
            placeholder="Enter Deploy URL"
            value={state.url}
            name="url"
            onChange={onChangeHandle}
            required
          />
          <input
            className="form-control bg-black text-white"
            placeholder="Poster Img"
            type="file"
            name="poster"
            value={postername}
            required
            onChange={(e) =>
              setposter(e.target.files[0], setPostername(e.target.files.name))
            }
          />
        </div>
        <div className="col-12 col-sm-6">
          <textarea
            placeholder="Description"
            className="form-control bg-black text-white h-90"
            value={state.description}
            name="description"
            onChange={onChangeHandle}
            required
          ></textarea>
        </div>
        <div className="col-12 col-sm-6">
          <div className="w-100 position-relative">
            <input
              className="form-control  bg-black text-white"
              placeholder="Enter Tech Stack"
              value={stackInputValue}
              onChange={onHandleStackInput}
            />
            <button
              className="d-inline position-absolute"
              style={{ top: "2px", right: "2px" }}
              onClick={onAdd}
              type="button"
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="w-100 form-control  bg-black text-white">
            {stackInput?.map((stack, index) => (
              <button
                key={index}
                type="button"
                class="btn btn-danger"
                style={{
                  padding: "4px",
                  marginRight: "5px",
                  marginBottom: "2px",
                }}
                data-mdb-ripple-init
                onClick={() => stackRemove(stack)}
              >
                {stack}
              </button>
            ))}
          </div>
        </div>
        <div className="col-12 col-sm-12">
          <button type="submit" className="btn btn-danger w-100 mt-2">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
