import React, { useState } from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import botConfig from "../../bot";
import { message } from "antd";

const Contact = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const onStateChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const formSubmit = (e) => {
    e.preventDefault();
    const apiUrl = `https://api.telegram.org/bot${botConfig.token}/sendMessage`;
    const messageText = `****** || New Message || ****** \n\n🌟 Name : ${state.name}\n📧 Email : ${state.email}\n📝 Message : ${state.message}\n\n*******************************`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: botConfig.chatId,
        text: messageText,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          console.log(messageText);
          messageApi.open({
            type: "success",
            content: "Message sent successfully",
          });
          setState({
            name: "",
            email: "",
            message: "",
          });
        } else {
          messageApi.open({
            type: "warning",
            content: "Failed to send message",
          });
          console.error("Failed to send message:", data.description);
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Error sending message",
        });
        console.error("Error sending message:", error);
      });
  };
  return (
    <div id="contact" className="section">
      {contextHolder}
      <div className="container">
        <div className="row">
          <div
            className="col-lg-5 col-lg-offset-1 col-md-6 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <address>
              <p className="contact-title" data-aos="fade-down">
                CONTACT ME
              </p>
              <p data-aos="fade-down">
                <i className="">
                  <FaPhoneAlt />
                </i>
                <a href="tel:+918607433682">+91 8607433682</a>
              </p>
              <p data-aos="fade-down">
                <i className="">
                  <FaEnvelope />
                </i>
                <a href="mailto:sudoarun.js@gmail.com">sudoarun.js@gmail.com</a>
              </p>
              <p data-aos="fade-down">
                <i className="">
                  <FaMapMarkerAlt />
                </i>{" "}
                Palwal,Haryana, 121102
              </p>
            </address>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-10 " data-aos="fade-up">
            <form onSubmit={formSubmit}>
              <input
                name="name"
                type="text"
                className="form-control"
                id="name"
                value={state.name}
                placeholder="Your Name"
                required
                onChange={onStateChange}
              />
              <input
                name="email"
                type="email"
                className="form-control"
                id="email"
                value={state.email}
                placeholder="Your Email"
                required
                onChange={onStateChange}
              />
              <textarea
                name="message"
                rows="5"
                className="form-control"
                id="message"
                required
                value={state.message}
                placeholder="Your Message"
                onChange={onStateChange}
              ></textarea>
              <button
                id="input"
                className="form-control"
                data-aos="fade-up"
                type="submit"
                //onClick="rest()"
              >
                Send Me
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
